import React from "react";
import { Link } from "react-router-dom";
import { BsCircleFill } from "react-icons/bs";

const ContactDemandsSummary = ({
  isRead,
  title,
  description,
  id,
}) => {
  return (
    <Link to={`/contact/${id}`}>
      <div
        className={`relative ${isRead ? "bg-gray-100" : "bg-blue-400"} 
        p-6 bg-opacity-50 mb-2 rounded-xl cursor-pointer transition duration-75 hover:bg-opacity-100 shadow-md`}
      >
        <div className="absolute left-0 top-1/2 transform -translate-y-1/2">
          <BsCircleFill
            className={`ml-3 h-2 w-2 text-blue-400 ${isRead ? "hidden" : ""}`}
          />
        </div>
        <h2 className=" ml-2 text-xl font-bold mb-2">{title}</h2>
        <p className="ml-2 text-gray-700">{description}</p>
      </div>
    </Link>
  );
};

export default ContactDemandsSummary;
