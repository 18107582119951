import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { whoami } from "../../api/user";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import { getPaymentOverview } from "../../api/data";
import { HiExternalLink } from "react-icons/hi";
import ContactDemandsSummary from "../../components/ContactDemandsSummary";
import OrdersSummary from "../../components/OrdersSummary";
// import OrderSummaryPopUp from "../../components/OrderSummaryPopUp";
import { getContactDemands } from "../../api/data";
import { getOrders } from "../../api/products";

export default function Home() {
  const navigate = useNavigate();
  const [paymentOverviewData, setPaymentOverviewData] = useState(null);
  const [ContactDemands, setContactDemands] = useState([]);
  const [Orders, setOrders] = useState([]);

  // const [isOrderSummaryPopUpOpened, setIsOrderSummaryPopUpOpened] =
  //   useState(false);

  // const toggleOrderSummarypopUpOpening = () => {
  //   setIsOrderSummaryPopUpOpened(!isOrderSummaryPopUpOpened);
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await whoami();
        if (response.statusText === "Forbidden") {
          // Check if the user is not logged in
          if (window.location.pathname !== "/") {
            navigate("/");
          }
        } else {
          if (!response.data.user.roles.includes("ROLE_ADMIN")) {
            navigate("/");
          } else {
            const paymentOverviewResponse = await getPaymentOverview();
            setPaymentOverviewData(paymentOverviewResponse.data.resObject);
          }
        }

        const contactDemandsResponse = await getContactDemands(3, 0);
        if (contactDemandsResponse.status === 200) {
          setContactDemands(contactDemandsResponse.data.demandsArray.reverse());
        }

        const ordersResponse = await getOrders();
        if (ordersResponse.status === 200) {
          if (ordersResponse.data.orders) {
            let orders = ordersResponse.data.orders.reverse();
            orders = orders.filter((order) => order.status !== "unpaid");
            setOrders(orders);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="bg-gray-100 flex-1 p-8 ml-64 h-max">
        <h2 className="text-4xl font-semibold mb-6">Bienvenue Florine 👋</h2>
        <p className="mb-12">Voici votre récapitulatif 📋</p>
        <div class="grid grid-cols-3 gap-4">
          {/* DIV HAUT GAUCHE */}
          <div class="bg-gray-200 p-4 col-span-1 shadow-md rounded-md">
            <h1 class="text-center text-3xl font-bold">Volume des ventes</h1>
            <div class="mt-4">
              <div class="flex items-center">
                <div class="flex-shrink-0 w-6 h-6 bg-blue-500 rounded-full mr-2"></div>
                <p class="text-left text-lg font-semibold">Montant brut</p>
              </div>
              {paymentOverviewData && (
                <p class="text-center text-gray-500">
                  {(paymentOverviewData.balance +
                    paymentOverviewData.unavailableBalance) /
                    100}{" "}
                  €
                </p>
              )}
            </div>
            <div class="mt-4">
              <div class="flex items-center">
                <div class="flex-shrink-0 w-6 h-6 bg-green-500 rounded-full mr-2"></div>
                <p class="text-left text-lg font-semibold">
                  Nombre de transactions
                </p>
              </div>
              {paymentOverviewData && (
                <p class="text-center text-gray-500">
                  {paymentOverviewData.TransactionsNumber}
                </p>
              )}
            </div>
            <div class="mt-4">
              {/* <div class="flex items-center">
                <div class="flex-shrink-0 w-6 h-6 bg-green-500 rounded-full mr-2"></div>
                <p class="text-left text-lg font-semibold">
                  Nombre de transactions
                </p>
              </div> */}

              <p class="text-center text-gray-500">
                De nouvelles statistiques arriveront certainement bientôt!
              </p>
            </div>
            {/* <div
              class="mt-8 text-center"
              onClick={() => setIsOrderSummaryPopUpOpened(true)}
            >
              <Link
                to="#"
                // CHANGER LIEN
                class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Voir plus
                <HiExternalLink className="w-5 h-5 ml-2" />
              </Link>
            </div> */}
          </div>
          {/* DIV HAUT DROIT */}
          <div class="bg-gray-200 p-4 col-span-2 shadow-md rounded-md">
            <h1 class="text-center text-3xl font-bold mb-4">
              Résumé des commandes
            </h1>{" "}
            {Orders.length > 0 ? (
              <>
                {Orders.map((order) => (
                  <OrdersSummary
                    isFinished={order.status === "done"}
                    title={
                      String(order.firstName) + " " + String(order.lastName)
                    }
                    description={
                      String(order.articles[0].type.toUpperCase()) +
                      " - " +
                      String(order.articles[0].description) +
                      "..."
                    }
                    id={String(order.invoiceId)}
                  />
                ))}
              </>
            ) : (
              <p className="text-gray-600">No orders to display.</p>
            )}
          </div>
          {/* DIV BAS GAUCHE */}
          <div class="bg-gray-200 p-4 col-span-1 shadow-md rounded-md">
            <h1 class="text-center text-3xl font-bold mb-4">
              Demandes de contact
            </h1>
            {ContactDemands.length > 0 ? (
              <>
                {ContactDemands.map((demand) => (
                  <ContactDemandsSummary
                    isRead={demand.replied}
                    title={String(demand.name)}
                    description={String(demand.contactDemandContent)}
                    id={String(demand.contactDemandId)}
                    demandsCount={ContactDemands.length}
                  />
                ))}
              </>
            ) : (
              <p className="text-gray-600">No contact demands to display.</p>
            )}
          </div>
          {/* DIV BAS DROIT */}
          <div class="p-4 col-span-2 rounded-md mt-16">
            <h1 class="text-center text-2xl font-bold mb-4">
              Je veux juste modifier du contenu:
            </h1>
            <div class="mt-8 text-center">
              <Link
                to="/personalization"
                class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Personnaliser le contenu du site
                <HiExternalLink className="w-5 h-5 ml-2" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <OrderSummaryPopUp
        isActive={isOrderSummaryPopUpOpened}
        content={""}
        closePopUp={toggleOrderSummarypopUpOpening}
      /> */}
    </div>
  );
}
