import { get, post } from "./index";

export const getContactDemands = (messagesNumber, alreadyLoaded) =>
  get(
    `/getcontact_demands?messagesNumber=${messagesNumber}&alreadyLoaded=${alreadyLoaded}`
  );
export const getContactDemand = (id) => get(`/getcontact_demand?id=${id}`);
export const getPaymentOverview = () => get("/payment_overview");
export const updateContactDemand = (id) =>
  post(`/update_contactdemand?id=${id}`);
export const listCarrousselSlides = () => get("/get_carrousselslides");
export const disableCarrousselSlide = (id) =>
  post(`/disable_carrsousselslide?slideId=${id}`);
export const getCarrousselSlide = (id) =>
  get(`/get_carrousselslide?slideId=${id}`);
export const updateCarrousselSlide = (carrousselSlideContent, image, id) =>
  post(`/update_carrousselslide?slideId=${id}`, {
    carrousselSlideContent,
    image,
  });
export const addCarrousselSlide = (carrousselSlideContent, image) =>
  post("/create_carrousselslides", { carrousselSlideContent, image });