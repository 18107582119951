import { post, get } from "./index";

export const getUncustomizedArticles = () => get("/uncustomizedarticles");
export const addUncustomizedArticle = (formData) =>
  post("/add_uncustomizedarticle", formData);
export const deleteUncustomizedArticle = (articleId) =>
  post(`/remove_uncustomizedarticle?articleId=${articleId}`);
export const getArticleQuantities = () => get("/get_article_availability");
export const editArticleQuantity = (formData) =>
  post("/edit_article_availability", formData);

export const getOrders = () => get("/get_orders");
export const getOrder = (orderId) => get(`/get_order?orderId=${orderId}`);
export const markOrderasDone = (orderId) =>
  post(`/mark_order_as_done?orderId=${orderId}`);
export const editUncustomizedArticle = (formData) =>
  post("/edit_uncustomizedarticle", formData);
export const getUncustomizedArticle = (articleId) =>
  get(`/uncustomizedarticle?articleId=${articleId}`);
