import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { getContactDemands } from "../../api/data";
import ContactDemandsSummary from "../../components/ContactDemandsSummary";

export default function ContactDemands() {
  const [ContactDemands, setContactDemands] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getContactDemands(100, 0).then((res) => {
          if (res.status === 200) {
            setContactDemands(res.data.demandsArray.reverse());
          }
        });
      } catch (error) {
        console.error("Error fetching contact demands:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Sidebar />
      <div className="flex-1 p-8 ml-64 h-max rounded-md">
        <h2 className="text-4xl font-semibold mb-6 text-gray-800">
          Demandes de contact
        </h2>
        <p className="mb-8 text-gray-600">
          Cliquez sur une demande pour la consulter complètement et y répondre.
        </p>
        {ContactDemands.length > 0 ? (
          <div className="bg-gray-200 p-2 col-span-1 shadow-md rounded-md gap-2">
            {ContactDemands.map((demand) => (
              <ContactDemandsSummary
                isRead={demand.replied}
                title={String(demand.name)}
                description={String(demand.contactDemandContent)}
                id={String(demand.contactDemandId)}
                demandsCount={ContactDemands.length}
              />
            ))}
          </div>
        ) : (
          <p className="text-gray-600">No contact demands to display.</p>
        )}
      </div>
    </>
  );
}
