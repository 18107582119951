import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { Link } from "react-router-dom";
import { listCarrousselSlides, disableCarrousselSlide } from "../../api/data";
import { FaEdit, FaTrash } from "react-icons/fa";

export default function Personnalisation() {
  const [CarrousselSlides, setCarrousselSlides] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await listCarrousselSlides();
        if (res.status === 200) {
          console.log(res.data);
          setCarrousselSlides(res.data.slides);
        }
      } catch (error) {
        console.error("Error fetching carroussel slides:", error);
      }
    };

    fetchData();
  }, []);

  const handleDeleteClick = async (slideId) => {
    await disableCarrousselSlide(slideId);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  return (
    <>
      <Sidebar />
      <div className="flex-1 p-8 ml-64 h-max rounded-md">
        {" "}
        <div className="mb-6 flex items-center justify-between">
          <h2 className="text-4xl font-semibold">Personnalisation du site</h2>
          <div className="space-x-4">
            <Link
              to={"/add_slide"}
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
            >
              Ajouter une nouvelle slide
            </Link>
          </div>
        </div>
        <div className="mb-8 text-gray-600">
          {CarrousselSlides.length > 0 ? (
            <div className="grid grid-cols-1 gap-4">
              {CarrousselSlides.map((slide) => (
                <div
                  key={slide.carrousselSlideId}
                  className="flex items-start bg-gray-100 p-2 col-span-1 shadow-md rounded-md gap-2"
                >
                  <div className="flex-1">
                    <h2 className="text-xl font-bold mb-2">{slide.title}</h2>
                    <p className="text-gray-700">{slide.description}</p>
                  </div>
                  <div className="flex-shrink-0 flex items-center my-auto space-x-4 mr-2">
                    <Link
                      to={`/edit_content/${slide.carrousselSlideId}`}
                      className="text-blue-500 hover:text-blue-700 text-xl"
                    >
                      <FaEdit />
                    </Link>
                    <button
                      onClick={() => handleDeleteClick(slide.carrousselSlideId)}
                      className="text-red-500 hover:text-red-700 text-xl"
                    >
                      <FaTrash />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-600">No carroussel slides to display.</p>
          )}
        </div>
      </div>
    </>
  );
}
