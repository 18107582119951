import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { getOrders } from "../../api/products";
import OrdersSummary from "../../components/OrdersSummary";

export default function ContactDemands() {
  const [Orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getOrders().then((res) => {
          if (res.status === 200) {
            let orders = res.data.orders.reverse();
            orders.forEach((order) => {
              if (order.status === "unpaid") {
                // Remove from the array
                orders.splice(orders.indexOf(order), 1);
              }
            });
            setOrders(orders);
          }
        });
      } catch (error) {
        console.error("Error fetching contact demands:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Sidebar />
      <div className="flex-1 p-8 ml-64 h-max rounded-md">
        <h2 className="text-4xl font-semibold mb-6 text-gray-800">Commandes</h2>
        <p className="mb-8 text-gray-600">
          Cliquez sur une commande pour consulter tous ses détails.
        </p>
        {Orders.length > 0 ? (
          <>
            {Orders.map((order) => (
              <>
                {order.articles[0].articleImage && (
                  <OrdersSummary
                    isFinished={order.status === "done"}
                    title={
                      String(order.firstName) + " " + String(order.lastName)
                    }
                    description={
                      String(order.articles[0].articleTitle).toUpperCase() +
                      " - " +
                      String(order.articles[0].articleDescription) +
                      "..."
                    }
                    id={String(order.invoiceId)}
                  />
                )}
                {order.articles[0].type && (
                  <OrdersSummary
                    isFinished={order.status === "done"}
                    title={
                      String(order.firstName) + " " + String(order.lastName)
                    }
                    description={
                      String(order.articles[0].type).toUpperCase() +
                      " - " +
                      String(order.articles[0].description) +
                      "..."
                    }
                    id={String(order.invoiceId)}
                  />
                )}
              </>
            ))}
          </>
        ) : (
          <p className="text-gray-600">No orders to display.</p>
        )}
      </div>
    </>
  );
}
