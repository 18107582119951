import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { useParams } from "react-router-dom";
import { getCarrousselSlide, updateCarrousselSlide } from "../../api/data";
import { AiOutlineSave } from "react-icons/ai";

export default function ContentEdition() {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    color: "#ffffff", // Default color
    image: null,
  });
  const [originalImage, setOriginalImage] = useState(null);

  const [imagePreview, setImagePreview] = useState(null);

  const params = useParams();
  const slideId = params.slideId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getCarrousselSlide(slideId);
        if (res.status === 200) {
          const data = res.data.slide;
          const image = res.data.image;
          setFormData({
            title: data.title,
            description: data.description,
            color: data.backgroundColor,
            link: data.link,
            image: image.content,
          });
          setOriginalImage(image.content); // Set original image
          setImagePreview(URL.createObjectURL(image)); // Set image preview
        }
      } catch (error) {
        console.error("Error fetching contact demand:", error);
      }
    };

    fetchData();
  }, [slideId]);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    setOriginalImage(null); // Reset original image
    const selectedImage = e.target.files[0];

    // Check if an image is selected
    if (selectedImage) {
      // Convert the selected image to a base64 string
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({
          ...formData,
          image: reader.result,
        });
        setOriginalImage(null); // Set original image as base64 string
      };
      reader.readAsDataURL(selectedImage);
    }

    // Set image preview
    setImagePreview(URL.createObjectURL(selectedImage));
  };

  const handleSave = async () => {
    const carrousselSlideContent = {
      title: formData.title,
      description: formData.description,
      backgroundColor: formData.color,
      link: formData.link,
    };
    await updateCarrousselSlide(
      carrousselSlideContent,
      formData.image,
      slideId
    );

    window.location.reload();
  };

  return (
    <>
      <Sidebar />
      <div className="container mx-auto mt-8">
        <h1 className="text-4xl font-semibold mb-6 ml-72">
          Modifier le contenu
        </h1>
        <div className="max-w-lg mx-auto bg-white p-8 rounded-md shadow-md">
          <div className="mb-4">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-600"
            >
              Titre
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-600"
            >
              Description
            </label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              rows="4"
              className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
            ></textarea>
          </div>
          <div className="mb-4">
            <label
              htmlFor="link"
              className="block text-sm font-medium text-gray-600"
            >
              Lien
            </label>
            <input
              type="text"
              id="link"
              name="link"
              value={formData.link}
              onChange={handleInputChange}
              className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="color"
              className="block text-sm font-medium text-gray-600"
            >
              Couleur de fond
            </label>
            <input
              type="color"
              id="color"
              name="color"
              value={formData.color}
              onChange={handleInputChange}
              className="mt-1 w-full h-10 border rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="image"
              className="block text-sm font-medium text-gray-600"
            >
              Image présentée
            </label>
            <input
              type="file"
              id="image"
              name="image"
              accept="image/*"
              onChange={handleImageChange}
              className="mt-1 border rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>
          {originalImage && (
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-600">
                Image originale
              </label>
              <img
                src={originalImage} // Use originalImage directly
                alt="Original"
                className="mt-2 w-full object-cover rounded-md"
              />
            </div>
          )}
          {imagePreview && (
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-600">
                Preview de la nouvelle image
              </label>
              <img
                src={imagePreview}
                alt="Preview"
                className="mt-2 w-full object-cover rounded-md"
              />
            </div>
          )}
          <div className="flex flex-row justify-end">
            <button
              onClick={handleSave}
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
            >
              <AiOutlineSave className="mr-2" /> Enregistrer
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
