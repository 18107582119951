import { createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Commands from "./pages/Commands/Commands";
import ArticleAddition from "./pages/ArticleAddition/ArticleAddition";
import ContactDemands from "./pages/ContactDemands/ContactDemands";
import Personnalisation from "./pages/Personnalisation/Personnalisation";
import Docs from "./pages/Docs/Docs";
import Help from "./pages/Help/Help";
import ContactDemand from "./pages/ContactDemand/ContactDemand";
import ContentEdition from "./pages/ContentEdition/ContentEdition";
import NoPersoArticleAddition from "./pages/NoPersoArticleAddition/NoPersoArticleAddition";
import PersoQuantityEdition from "./pages/PersoQuantityEdition/PersoQuantityEdition";
import Command from "./pages/Command/Command";
import NoPersoArticleEdition from "./pages/NoPersoArticleEdition/NoPersoArticleEdition";
import ContentAddition from "./pages/ContentAddition/ContentAddition";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/dashboard",
    element: <Home />,
  },
  {
    path: "/commands",
    element: <Commands />,
  },
  {
    path: "/add_articles",
    element: <ArticleAddition />,
  },
  {
    path: "/contact_demands",
    element: <ContactDemands />,
  },
  {
    path: "/personalization",
    element: <Personnalisation />,
  },
  {
    path: "/docs",
    element: <Docs />,
  },
  {
    path: "/help",
    element: <Help />,
  },
  {
    path: "/contact/:demandId",
    element: <ContactDemand />,
  },
  {
    path: "/edit_content/:slideId",
    element: <ContentEdition />,
  },
  {
    path: "/add_unpersonnalizedarticle",
    element: <NoPersoArticleAddition />,
  },
  {
    path: "/edit_article_quantities",
    element: <PersoQuantityEdition />,
  },
  {
    path: "/command/:orderId",
    element: <Command />,
  },
  {
    path: "/edit_unpersonnalizedarticle/:articleId",
    element: <NoPersoArticleEdition/>
  },
  {
    path: "/add_slide",
    element: <ContentAddition/>
  }
]);
