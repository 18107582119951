import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { whoami } from "../../api/user";
import { useNavigate } from "react-router-dom";
import { getUncustomizedArticles } from "../../api/products";
import UnpersonalizedArticlesListElement from "../../components/UnpersonnalizedArticlesList";
import { Link } from "react-router-dom";

export default function ArticleAddition() {
  const navigate = useNavigate();
  const [Articles, setArticles] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await whoami();
        if (
          response.statusText === "Forbidden" ||
          !response.data.user.roles.includes("ROLE_ADMIN")
        ) {
          navigate("/");
        } else {
          getUncustomizedArticles()
            .then((ArticlesData) => {
              setArticles(ArticlesData.data.articles);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
              setError(error.message);
            });
        }
      } catch (err) {
        console.log(err);
        navigate("/");
      }
    };

    fetchData();
  }, [navigate]);

  return (
    <>
      <Sidebar />
      <div className="flex-1 p-8 ml-64 h-max">
        <div className="mb-6 flex items-center justify-between">
          <h2 className="text-4xl font-semibold">Articles non personnalisés</h2>
          <div className="space-x-4">
            <Link
              to={"/edit_article_quantities"}
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
            >
              Article personnalisés (qté)
            </Link>
            <Link
              to={"/add_unpersonnalizedarticle"}
              className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
            >
              Ajouter un nouvel article (no perso)
            </Link>
          </div>
        </div>
        <table className="w-full table-fixed border-collapse mt-16">
          <thead className="thead-light">
            <tr>
              <th className="mb-4">Titre</th>
              <th>Description</th>
              <th>Prix</th>
              <th>Unités</th>
              <th>Image</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {error ? (
              <tr>
                <td
                  colSpan="6"
                  className="text-red-500"
                >{`Error: ${error}`}</td>
              </tr>
            ) : (
              Articles.map((articleData, index) => (
                <>
                  <UnpersonalizedArticlesListElement
                    key={index}
                    data={articleData}
                  />
                </>
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
