import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import { addUncustomizedArticle } from "../../api/products";
import { AiOutlineSave, AiOutlineClose } from "react-icons/ai";

export default function NoPersoArticleAddition() {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    quantity: "",
    price: 0,
    images: [],
  });

  const handleImageChange = (e) => {
    const selectedImages = e.target.files;

    if (selectedImages.length > 0) {
      Promise.all(
        Array.from(selectedImages).map((image) => {
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.readAsDataURL(image);
          });
        })
      ).then((base64Images) => {
        setFormData((prevData) => ({
          ...prevData,
          images: [...prevData.images, ...base64Images],
        }));
      });
    } else {
    }
    console.log(formData.images);
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  function handleDelete(index) {
    setFormData((prevState) => {
      const newImages = [...prevState.images];
      newImages.splice(index, 1);
      return { ...prevState, images: newImages };
    });
  }

  const handleSave = async () => {
    const dataToSend = {
      title: formData.title,
      description: formData.description,
      quantity: formData.quantity,
      price: formData.price,
      images: formData.images,
    };
    console.log(dataToSend);
    await addUncustomizedArticle(dataToSend);
    window.location.href = "/add_articles";
  };
  return (
    <>
      <Sidebar />
      <div className="container mx-auto mt-8">
        {" "}
        <h1 className="text-4xl font-semibold mb-6 ml-72">
          Ajouter un article non personnalisé
        </h1>
        <div className="max-w-lg mx-auto bg-white p-8 rounded-md shadow-md">
          <div className="mb-4">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-600"
            >
              Titre
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-600"
            >
              Description
            </label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              rows="4"
              className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
            ></textarea>
          </div>
          <div className="mb-4">
            <label
              htmlFor="quantity"
              className="block text-sm font-medium text-gray-600"
            >
              Quantité
            </label>
            <input
              type="text"
              id="quantity"
              name="quantity"
              value={formData.quantity}
              onChange={handleInputChange}
              className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="price"
              className="block text-sm font-medium text-gray-600"
            >
              Prix (en € & format: "30.99€")
            </label>
            <input
              type="text"
              id="price"
              name="price"
              value={formData.price}
              onChange={handleInputChange}
              className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="image"
              className="block text-sm font-medium text-gray-600"
            >
              Image présentée
            </label>
            <input
              type="file"
              id="image"
              name="image"
              accept="image/*"
              onChange={handleImageChange}
              className="mt-1 border rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>
          {formData.images.length > 0 &&
            formData.images.map((image, index) => (
              <div
                key={index}
                className="image-container relative mb-8 rounded-xl "
              >
                <img
                  src={image}
                  alt={`${index}`}
                  className="image rounded-md bg-white bg-opacity-60 hover:bg-opacity-100 shadow-xl "
                />
                <div className="absolute top-0 right-0 p-2 bg-white bg-opacity-60 hover:bg-opacity-100 rounded-md">
                  <button
                    onClick={() => handleDelete(index)}
                    className="text-red-500"
                  >
                    <AiOutlineClose />
                  </button>
                </div>
              </div>
            ))}

          <div className="flex flex-row justify-end">
            <button
              onClick={handleSave}
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
            >
              <AiOutlineSave className="mr-2" /> Enregistrer
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
