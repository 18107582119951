import React from "react";
import { Link } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import { deleteUncustomizedArticle } from "../api/products";

const UnpersonalizedArticlesListElement = ({ data }) => {
  const handleDeleteClick = async (articleId) => {
    await deleteUncustomizedArticle(String(articleId));
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  return (
    <>
      <tr className="text-center">
        <td className="truncate">{String(data.articleTitle)}</td>
        <td className="truncate">{String(data.articleDescription)}</td>
        <td>{String(data.price)} €</td>
        <td>{String(data.quantity)}</td>
        <td>
          <img
            src={String(data.articleImage[0])}
            alt="Article"
            className="w-16 h-16 object-cover rounded-md mx-auto"
          />
        </td>
        <td className="flex items-center space-x-4">
          <div className="flex items-center space-x-4 mx-auto">
            <Link
              to={`/edit_unpersonnalizedarticle/${String(data.articleId)}`}
              className="text-blue-500 hover:text-blue-700 text-xl"
            >
              <FaEdit />
            </Link>
            <button
              onClick={() => handleDeleteClick(String(data.articleId))}
              className="text-red-500 hover:text-red-700 text-xl"
            >
              <FaTrash />
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default UnpersonalizedArticlesListElement;
