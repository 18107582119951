import React from "react";
import Sidebar from "../../components/Sidebar";

export default function Docs() {
  return (
    <>
      <Sidebar />
      <div className="flex-1 p-8 ml-64 h-max rounded-md">
        <h2 className="text-4xl font-semibold mb-6 text-gray-800">
          Documents d'aide sur votre site
        </h2>
        <p className="mb-8 text-gray-600">
          Ratio des images: 16:9 (1920x1080, 1280x720, 854x480, 640x360)
          Poids maximum/image reommandé: 700Ko
          Poids maximum/image autorisé: 2Mo 
        </p>
      </div>
    </>
  );
}
