import React from "react";
import Sidebar from "../../components/Sidebar";

export default function Help() {
  return (
    <>
      <Sidebar />
      <div className="flex-1 p-8 ml-64 h-max rounded-md">
        <h2 className="text-4xl font-semibold mb-6 text-gray-800">
          Besoin d'aide?
        </h2>
        <p className="mb-8 text-gray-600">
          Contactez le développeur du site qui pourra répondre à toutes vos questions!
        </p>

        <a
          className="inline-block px-6 py-3 text-lg font-semibold text-white bg-blue-500 rounded-full shadow-md transition duration-300 ease-in-out transform hover:scale-105 hover:bg-blue-600"
          href="mailto:contact@techxplorer.fr"
        >
          Contacter le développeur
        </a>
      </div>
    </>
  );
}
