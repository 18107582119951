import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { useParams } from "react-router-dom";
import { getContactDemand, updateContactDemand } from "../../api/data";

export default function ContactDemand() {
  const [ContactInfo, setContactInfo] = useState(null);
  const { demandId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getContactDemand(demandId);
        if (res.status === 200) {
          setContactInfo(res.data.demand[0]);
          const updatedResponse = await updateContactDemand(demandId);
          if (updatedResponse.status === 200) {
            console.log("Demand updated");
          }
        }
      } catch (error) {
        console.error("Error fetching contact demands:", error);
      }
    };

    fetchData();
  }, [demandId]);

  const mailtoLink =
    ContactInfo &&
    `mailto:${ContactInfo.email}?subject=RE:${encodeURIComponent(
      ContactInfo.name
    )}&body=${encodeURIComponent(
      `Name: ${ContactInfo.name}%0AEmail: ${ContactInfo.email}%0AMessage: ${ContactInfo.contactDemandContent}`
    )}`;

  // A finir

  return (
    <>
      <Sidebar />
      <div className="flex-1 p-8 ml-64 h-max rounded-md">
        <h2 className="text-4xl font-semibold mb-6 text-gray-800">
          {ContactInfo && ContactInfo.name}
        </h2>
        <hr/>
        <p className="mt-1 mb-1">{ContactInfo && ContactInfo.email}</p>
        <p className="mb-1">{ContactInfo && ContactInfo.createdAt}</p>
        <hr/>
        <h4 className="text-2xl mt-2 mb-2"> Contenu du message:</h4>
        <p className="mb-8 text-gray-600">
          {ContactInfo && ContactInfo.contactDemandContent}
        </p>
        {/* Reply button */}
        <div className="flex justify-end">
          {mailtoLink && (
            <a
              href={mailtoLink}
              className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-md"
            >
              Répondre
            </a>
          )}
        </div>
      </div>
    </>
  );
}
