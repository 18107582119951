import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getOrder, markOrderasDone } from "../../api/products";
import Sidebar from "../../components/Sidebar";

export default function Command() {
  const { orderId } = useParams();

  const [Order, setOrder] = useState({});
  const [StripeOrder, setStripeOrder] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getOrder(String(orderId));
        if (res.status === 200) {
          console.log(res.data);
          setOrder(res.data.order);
          setStripeOrder(res.data.stripe_invoice);
        }
      } catch (error) {
        console.error("Error fetching order:", error);
      }
    };

    fetchData();
  }, [orderId]);

  const handleOrderValidation = async () => {
    try {
      const res = await markOrderasDone(String(orderId));
      if (res.status === 200) {
        console.log("Order marked as done");
        window.location.reload();
      }
    } catch (error) {
      console.error("Error marking order as done:", error);
    }
  };

  console.log(StripeOrder)

  return (
    <>
      <Sidebar />

      <div className="flex-1 p-8 ml-64 h-max rounded-md">
        <h2 className="text-4xl font-semibold mb-6 text-gray-800">
          Commande {Order.invoiceId} - {Order.firstName} {Order.lastName}
        </h2>
        <div className="flex justify-end">
          <button
            onClick={handleOrderValidation}
            className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-md mb-2"
          >
            Marquer comme terminé
          </button>
        </div>
        <hr />
        <h4 className="text-2xl mt-2 mb-2"> Informations de contact:</h4>
        <p className="mt-1 mb-1">{Order.email}</p>
        <p className="mb-1">{Order.phone}</p>
        <hr />
        <h4 className="text-2xl mt-2 mb-2"> Adresse de livraison:</h4>
        <p className="mt-1 mb-1">{Order.address}</p>
        <p className="mb-1">
          {Order.postalCode} {Order.city}
        </p>
        <hr />
        <h4 className="text-2xl mt-2 mb-2"> Informations de paiement:</h4>
        <p className="mt-1 mb-1">Statut de paiement {StripeOrder.status}</p>
        {/* <p className="mb-1">Payé le: {new Date(StripeOrder.status_transitions.paid_at * 1000)}</p> */}
        {/* Cannot be done for the moment because of stripe limitations */}
        <hr />
        <h4 className="text-2xl mt-2 mb-2"> Articles:</h4>
        <div className="flex flex-col">
          {Order.articles &&
            Order.articles.map((article) => (
              <div className="flex flex-row justify-between">
                <p className="mt-1 mb-1">
                  {article.type}
                  {article.articleTitle} - {article.description}
                  {article.articleDescription}
                </p>
                <p className="mt-1 mb-1 ml-8 mr-8">
                  {Math.round(Number(article.price)) / 100}€
                </p>
                {article.image && (
                  <img src={article.image} alt={article.description} />
                )}
                {article.articleImage && (
                  <img src={article.articleImage} alt={article.articleTitle} className="h-96 w-96"/>
                )}
              </div>
            ))}

          <hr />
          <div className="flex flex-row justify-between">
            <p className="mt-1 mb-1">Total:</p>
            <p className="mt-1 mb-1">
              {Math.round(Number(StripeOrder.total)) / 100}€
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
