import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import { getArticleQuantities, editArticleQuantity } from "../../api/products";

export default function PersoQuantityEdition() {
  const [quantities, setQuantities] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getArticleQuantities();
        if (response.status === 200) {
          setQuantities(response.data.items);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e, id) => {
    const updatedQuantities = quantities.map((quantity) =>
      quantity.id === id ? { ...quantity, quantity: e.target.value } : quantity
    );
    setQuantities(updatedQuantities);
  };

  const handleSave = async () => {
    try {
      const response = await editArticleQuantity({ articles: quantities });
      if (response.status === 200) {
        window.location.href = "/add_articles";
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Sidebar />

      <div className="flex-1 p-8 ml-64 h-max">
        <div className="mb-6 flex items-center justify-between">
          <h2 className="text-4xl font-semibold">
            Quantités des articles personnalisés
          </h2>
          <button
            className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
            onClick={handleSave}
          >
            Enregistrer
          </button>
        </div>

        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Article
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Quantité
                      </th>
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-gray-200">
                    {quantities.map((quantity) => (
                      <tr key={quantity.id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">
                                {quantity.id}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">
                                <input
                                  type="number"
                                  id={quantity.id}
                                  name={quantity.id}
                                  value={quantity.quantity}
                                  onChange={(e) =>
                                    handleInputChange(e, quantity.id)
                                  }
                                  className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
